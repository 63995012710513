import posthog from 'posthog-js'

const Telemetry = {
  init() {
    if (process.env.NEXT_PUBLIC_POSTHOG_KEY) {
      posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY, {
        api_host: 'https://app.posthog.com',
      })
      posthog.startSessionRecording()
    } else {
      console.warn('Posthog API key not found, tracking not initialized.')
    }
  },

  track(msg, props = {}) {
    if (process.env.NEXT_PUBLIC_POSTHOG_KEY) {
      posthog.capture(msg, props)
    } else {
      console.warn('Posthog API key not found, tracking not initialized.')
    }
  },

  pageview() {
    if (process.env.NEXT_PUBLIC_POSTHOG_KEY) {
      Telemetry.track('$pageview')
    } else {
      console.warn('Posthog API key not found, tracking not initialized.')
    }
  },
}

export default Telemetry

import { useRouter } from 'next/router'
import Telemetry from '../logging/telemetry'
import { useEffect } from 'react'

const useTelemetry = () => {
  const router = useRouter()

  useEffect(() => {
    Telemetry.init()

    const handleRouteChange = () => Telemetry.pageview()
    router.events.on('routeChangeComplete', handleRouteChange)

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [])
}

export default useTelemetry

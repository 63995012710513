import '../styles/styles.scss'
import * as gtag from '../lib/gtag'
import Router from 'next/router'
import useTelemetry from '../utils/hooks/useTelemetry'

export function checkProduction (props) {
  // Check if in production

  const isProduction = process.env.NODE_ENV === 'production'

  return {
    ...initialProps,
    isProduction
  }
  return (
    isProduction &&
    Router.events.on('routeChangeComplete', url => gtag.pageview(url))
  )
}

function MyApp ({ Component, pageProps }) {
  useTelemetry()
  return <Component {...pageProps} />
}

export default MyApp
